import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { comments } from "../schemas/formSchemas";
import axios from "axios";
import { toast } from "react-toastify";
import SkeletonLoader from "./SkeletonLoader";

const Comments = ({ blog }) => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  // const [name, setName] = useState(null);
  // const [subject, setSubject] = useState(null);
  // const [comment, setComment] = useState([]);

  // useEffect(() => {
  //   const fetchComments = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://onlineappointment.onrender.com/api/blog_comment"
  //       );
  //       setComment(response.data); // Store fetched comments in state
  //     } catch (err) {
  //       console.error("Error fetching comments:", err);
  //       console.log(err);
  //       // Stop loading even if there's an error
  //     }
  //   };

  //   fetchComments();
  // }, []);

  // const filteredComments = comment.filter((singleComment) =>
  //   console.log("s", singleComment.heading)
  // );
  // console.log("b", blog);

  const formik = useFormik({
    initialValues: {
      name: "",
      phonenumber: "",
      email: "",
      comment: "",
      subject: "",

      // captcha: "",
    },
    validationSchema: comments,
    onSubmit: async (values, actions) => {
      setLoading(true);
      setSubmitting(null);

      const commentDB = {
        name: values.name,
        phone: values.phonenumber,
        email: values.email,
        subject: values.subject,
        comment: values.comment,
        heading: blog || "head",
      };
      console.log(commentDB);

      try {
        const response = await axios.post(
          // "https://onlineappointment.onrender.com/blog/comment",
          // "http://183.83.188.205:60162/api/blog/comment/mail",
          "https://elinaservices.com:60162/api/blog/comment/mail",

          commentDB
        );
        // alert("Form submitted successfully");
        setSubmitting("success");
        actions.resetForm();
      } catch (err) {
        setSubmitting("error");
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (submitting === "success") {
      toast.success("Your comment has been submitted successfully", {
        position: "top-center",
        className: "text-white",
      });
    } else if (submitting === "error") {
      toast.error("There was an error submitting the comment", {
        position: "top-center",
        className: "text-red-500 bg-white",
      });
    }
  }, [submitting]);

  return (
    <>
      {/* <>
        <div className="bg-blue-100 lg:mx-[10%] p-10 rounded-2xl">
          {filteredComments.map((comment, index) => (
            <div key={index} className="comment bg-white p-5 rounded-md my-2">
              <p className="font-bold">{comment.name || "No name provided"}</p>
              <p>{comment.subject || "No subject provided"}</p>
            </div>
          ))}
        </div>
      </> */}
      <>
        <div className="comments p-5 lg:p-0">
          <div className="lg:mx-[10%]   border-gray-900 bg-gray-200 rounded-3xl my-10">
            {loading ? (
              <>
                <SkeletonLoader itemCount={6} />{" "}
              </>
            ) : (
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col justify-center  w-full p-10 gap-3 lg:gap-5"
              >
                <p className="flex justify-left text-center text-4xl font-black text-blue-950">
                  Leave a comment
                </p>
                <label for="name">
                  Name:<span className="text-red-500">*</span>
                </label>
                <input
                  id="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  class={`${
                    formik.errors.name && formik.touched.name
                      ? "border border-red-600"
                      : " "
                  } w-full border-b-1 border-t-0 border-x-0 rounded-md`}
                  type="text"
                  placeholder="Enter your name"
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="text-sm font-semibold text-red-500">
                    {formik.errors.name}
                  </p>
                )}
                <label for="phonenumber">
                  Phone number:<span className="text-red-500">*</span>
                </label>

                <input
                  id="phonenumber"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.phonenumber}
                  class={`${
                    formik.errors.phonenumber && formik.touched.phonenumber
                      ? "border border-red-600"
                      : " "
                  } w-full border-b-1 border-t-0 border-x-0 rounded-md`}
                  type="tel"
                  placeholder="Enter your phone number"
                />
                {formik.errors.phonenumber && formik.touched.phonenumber && (
                  <p className="text-sm font-semibold text-red-500">
                    {formik.errors.phonenumber}
                  </p>
                )}
                <label for="email">
                  Email address:<span className="text-red-500">*</span>
                </label>

                <input
                  id="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  class={`${
                    formik.errors.email && formik.touched.email
                      ? "border border-red-600"
                      : " "
                  } w-full border-b-1 border-t-0 border-x-0 rounded-md`}
                  type="email"
                  placeholder="Enter your email address"
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="text-sm font-semibold text-red-500">
                    {formik.errors.email}
                  </p>
                )}

                {/* Subject */}

                <label for="name">
                  Subject:<span className="text-red-500">*</span>
                </label>
                <input
                  id="subject"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                  class={`${
                    formik.errors.subject && formik.touched.subject
                      ? "border border-red-600"
                      : " "
                  } w-full border-b-1 border-t-0 border-x-0 rounded-md`}
                  type="text"
                  placeholder="Enter subject"
                />
                {formik.errors.subject && formik.touched.subject && (
                  <p className="text-sm font-semibold text-red-500">
                    {formik.errors.subject}
                  </p>
                )}

                <label for="comment">
                  Comment:<span className="text-red-500">*</span>
                </label>

                <textarea
                  id="comment"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.comment}
                  class={`${
                    formik.errors.comment && formik.touched.comment
                      ? "border border-red-600"
                      : " "
                  } w-full border-b-1 border-t-0 border-x-0 rounded-md`}
                />
                {formik.errors.comment && formik.touched.comment && (
                  <p className="text-sm font-semibold text-red-500">
                    {formik.errors.comment}
                  </p>
                )}
                {/* <div id="captcha" className="lg:py-5 py-2">
              <ReCAPTCHA sitekey="6LceNQYqAAAAANmxHgRcfdU_e8KW_c05MKTOBai3" />

              {formik.errors.captcha && formik.touched.captcha ? (
                <p className="text-sm font-semibold text-red-500">
                  {formik.errors.captcha}
                </p>
              ) : (
                ""
              )}
            </div> */}
                <button
                  type="submit"
                  disabled={formik.isSubmitting}
                  className="flex justify-start bg-blue-950 border-blue-950 text-white hover:bg-white hover:text-blue-950 hover:border-blue-950 w-fit py-3 px-7"
                >
                  Publish
                </button>
              </form>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default Comments;
