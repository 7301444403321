import React from "react";
import sail from "../../images/sail.jpg";
import compass from "../../images/compass2.jpeg";
const ProgramContent = [
  {
    h1: "SAIL",
    p1: (
      <span>
        <span className="text-pink-600 font-black text-lg">S</span>etting up{" "}
        <span className="text-pink-600 font-black text-lg">A</span>ccess to{" "}
        <span className="text-pink-600 font-black text-lg">I</span>ndependence
        and <span className="text-pink-600 font-black text-lg">L</span>earning
      </span>
    ),
    p: (
      <span>
        The SAIL program is a short-term process lasting about four weeks that
        involves:
      </span>
    ),
    li: [
      <span>
        <span className="font-bold text-pink-600">
          Comprehensive assessments:{" "}
        </span>
        Understand your child’s unique strengths and challenges through thorough
        evaluations.
      </span>,
      <span>
        <span className="font-bold text-pink-600">
          Personalized recommendations:{" "}
        </span>
        Receive expert guidance on managing behaviors, enhancing learning, and
        fostering social skills.
      </span>,
    ],
    src: sail,
  },
  {
    h1: "CoMPASS",
    p1: (
      <span>
        <span className="text-lg font-black text-pink-600">Co</span>ntinuous{" "}
        <span className="text-lg font-black text-pink-600">M</span>apping of{" "}
        <span className="text-lg font-black text-pink-600">P</span>rogress{" "}
        <span className="text-lg font-black text-pink-600">A</span>bilities{" "}
        <span className="text-lg font-black text-pink-600">S</span>trengths and{" "}
        <span className="text-lg font-black text-pink-600">S</span>kills
      </span>
    ),
    p: "The CoMPASS Program is a longer-term initiative that spans six months, focusing on:",
    li: [
      <span>
        <span className="font-bold text-pink-600">Collaborative Support: </span>
        Bringing together a team of professionals, including educators,
        therapists, and doctors, to create a personalized support plan for your
        child.
      </span>,
      <span>
        <span className="font-bold text-pink-600">Continuous Monitoring: </span>
        Regularly assessing and adjusting interventions to ensure ongoing
        progress and development.
      </span>,
      <span>
        <span className="font-bold text-pink-600">Empowering Parents: </span>
        Providing you with the knowledge and tools to support your child's
        growth, helping them thrive both at home and beyond.
      </span>,
    ],
    src: compass,
  },
];

const Programs = () => {
  return (
    <>
      <div className="HeroBg lg:px-32 md:px-32 py-28">
        <h1 className="flex justify-center items-center text-5xl lg:text-6xl font-black text-center text-pink-600 pb-5">
          Welcome to Elina
        </h1>
        <h1 className="flex justify-center items-center text-3xl font-black text-center text-pink-600 pb-5">
          Supporting you every step of the way
        </h1>
        <p className="lg:pb-20 md:p-5 py-10 px-2 text-wrap text-justify md:px-0 md:pb-6 lg:px-14 text-[1rem] md:text-lg font-medium md:font-bold md:text-gray-800">
          Parenting a neurodivergent child comes with its own set of
          challenges—feeling overwhelmed, unsure where to turn, and constantly
          questioning if you're doing enough. School concerns, developmental
          delays, or behavioral struggles can leave you feeling isolated and
          searching for solutions.
          <br />
          <br />
          At Elina, we understand your journey. We offer the guidance, clarity,
          and support you need through our tailored, evidence-based programs
          like SAIL and CoMPASS, helping you confidently support your child's
          growth and realise their full potential.
        </p>

        <div className="grid grid-cols-1 gap-16 p-5 md:grid-cols-1 md:gap-4 lg:gap-20 justify-center sm:grid-cols-2 lg:grid-cols-2">
          {ProgramContent.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center drop-shadow-xl rounded-3xl bg-white p-5"
            >
              <h1 className="text-3xl text-gray-800 font-black lg:text-3xl lg:px-10 lg:pt-10">
                {item.h1}
              </h1>
              <p className="text-center md:px-28 pb-5 font-bold">{item.p1}</p>
              <img
                className="rounded-2xl w-[300px] h-[200px] lg:w-[400px] lg:h-[300px] object-cover mb-5"
                src={item.src}
                alt={item.h1}
              />
              <div className=" lg:min-h-24">
                <p className="text-left lg:text-lg text-gray-800 font-semibold mb-5 lg:px-14">
                  {item.p}
                </p>
              </div>
              <div>
                <ul className="list-disc  list-inside mb-5 lg:px-10">
                  {item.li.map((i, idx) => (
                    <li key={idx} className="px-4 lg:text-lg text-gray-600">
                      {i}
                    </li>
                  ))}
                </ul>
              </div>
              {/* <button className="flex items-center bg-pink-500 border-pink-500 hover:text-pink-600 p-2 lg:gap-1 rounded-lg mt-auto mb-4 lg:p-1 lg:px-2">
                Read more
              </button> */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Programs;
