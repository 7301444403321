import React from "react";

const Introduction = [
  {
    p: "These terms of use (the “Terms of Use”), read together with the privacy policy located at www.elinaservices.com/privacypolicy (“Privacy Policy”) constitute a legal and binding agreement (“Agreement”) between You (hereinafter referred to as “You”, or “Your” or “User(s)” and Vimarshi Solutions Private Limited and its brand “Elina” (the “Company”), having its registered office at C1-301, Pelican Nest, Creek Street, Okkium Thoraipakkam, Chennai 600097, India and provides, inter alia, the terms that govern Your access and use of the Company’s website, Services and products (“Website”).",
  },
  {
    p: "These Terms of Use constitute an electronic record in accordance with the provisions of the Information Technology Act, 2000 and the Information Technology (Intermediaries guidelines) Rules, 2011 thereunder, as amended from time to time.",
  },
  {
    p: "These Terms of Use apply to all users of the Website including students, their parents and educators visiting the Website who may directly or indirectly be providing content for the Website.",
  },
  {
    p: "The Website and the domain name i.e. https://www.elinaservices.com and https://www.elinaservices.com all its content, information, material, trademarks, services marks, trade names, and trade secrets including but not limited to the software, text, images, graphics, video(s) and related coaching material, script and audio, contained in the Website are proprietary information of the Company.",
  },
  {
    p: (
      <span>
        By using or visiting the Website or any software, data feeds, and
        service provided to You on, from, or through the Website, You signify
        Your agreement to
        <ul className="list-disc">
          <li>These Terms of Use,</li>
          <li>
            Privacy Policy and any other terms that are updated from time to
            time. If You do not agree to any of these terms, please do not use
            the Website.
          </li>
        </ul>
      </span>
    ),
  },
  {
    p: "Please read the Terms of Use and the Privacy Policy of the Company with respect to registration with us, the use of the Website, Services and products carefully before using the Website, Services or products. Any capitalized term used in this term of use and not defined herein shall have the meaning as defined in the Privacy Policy. In the event of any discrepancy between the Terms of Use and any other policies with respect to the Website or Services or products, the provisions of these Terms of Use shall prevail",
  },
  {
    p: "This Agreement does not alter in any way the terms or conditions of any other agreement You may have with us, or our subsidiaries or affiliates, for Services or otherwise. If You are using the Website on behalf of any entity, You represent and warrant that You are authorized to accept these terms on such entity’s behalf, and that You agree to indemnify us for violation of this Agreement.",
  },
];

const WebsiteUse = [
  {
    p: "The primary objective of the Website is to establish a communication platform that enables Users to enroll and familiarize themselves with structured information, facilitating the tracking and reporting of a child's learning and progress. The utilization of the Website and its associated offerings is strictly intended for advisory and guidance purposes and should not be considered a substitute for the curriculum-based education provided by educational institutions in India or other countries. The Website serves as an additional resource, providing supplementary materials to enhance the understanding of the child’s environment, aiming to empower the User with all the necessary information and related analysis.",
  },
  {
    p: "The Company acknowledges the existence of diverse methods for delivering and facilitating structured approaches. Inclusion or exclusion of specific methods within the Services/products offered on the Website does not imply endorsement or disapproval of any particular educational institution, establishment, or their respective offerings engaged in similar endeavours.",
  },
  {
    p: "Subscription of any offerings on the Website for procuring Services does not in any manner guarantee any higher grades/enhanced performance in any manner whatsoever or admission to any educational institutions in India or other countries or clearing/passing of any exams or achievement of any specified percentage of marks in any examinations whatsoever. The Company at no point provides for any warranties or representations whatsoever regarding the quality, content, completeness, or adequacy of such information and data available on the Website.",
  },
  {
    p: "1. A condition of the User’s use of and access to the Website and to the services provided by the Company (the “Services”) is the User’s acceptance of this Agreement. Any User that does not agree with any provisions of the same is required to leave this Website immediately and immediately discontinue use of all other Services.",
  },
  {
    p: "2. Use of Services on the Website means you are consenting to these Terms of Use.",
  },
  {
    p: "3. You agree not to use or launch any automated system, including without limitation, “robots,” “spiders,” or “offline readers,” that accesses the Website in a manner that sends more request messages to Company’s servers in a given period of time than a human can reasonably produce in the same period by using a conventional on-line web browser. Notwithstanding the foregoing, Company grants the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials. Company reserves the right to revoke these exceptions either generally or in specific cases at any time with or without providing any notice in this regard.",
  },
  {
    p: "4. You agree not to collect or harvest any personally identifiable information, including account names, from the Website, nor to use the communication systems provided by the Website for any commercial solicitation purposes. You agree not to solicit, for commercial purposes, any Users of the Website with respect to User content.",
  },
  {
    p: "5. The Company hereby grants You a limited, personal, revocable, non-sub licensable and nonexclusive license to access and use the Service for personal or internal business purpose through the Website. This license granted herein does not include any collection, aggregation, copying, duplication, display or derivative use of the Services nor any use of data mining, robots, spiders, or similar data gathering and extraction tools for any purpose unless otherwise set forth in this Agreement.",
  },
  {
    p: "6. Any use of the Services and/or Website beyond the scope of authorized access as set forth in these Terms of Use immediately terminates any permission or license granted herein.",
  },
  {
    p: (
      <span>
        7. In addition to the above, You further acknowledge and agree that You
        will not:
        <ul className="list-disc">
          <li>Reproduce or attempt to reproduce the Website;</li>
          <li>
            Modify, adapt, translate or create any derivative works of the
            Website or attempt to do the foregoing;
          </li>
          <li>
            Attempt to circumvent or disable the Website or any technology,
            features or measures in the Website by any means or in any manner;
          </li>
          <li>
            {" "}
            Attempt to decompile, disassemble, reverse engineer, or otherwise
            attempt to derive the source code for the Website;
          </li>
          <li>
            Distribute, encumber, sell, rent, lease, sub-license the Website to
            any third party;
          </li>
          <li>
            Access or use the Website/Services/products in any manner that could
            damage, disable, overburden or impair any of the Website’s servers
            or the networks connected to any of the servers on which the Website
            is hosted.
          </li>
          <li>
            Make available any content or material that You do not have a right
            to make available under any law or contractual or fiduciary
            relationship, unless You own or control the rights thereto or have
            received all necessary consents for such use of the content.
          </li>
          <li>
            {" "}
            Remove or alter any trademark, logo, copyright or other proprietary
            notices, legends, symbols or labels in or on the Website or used in
            connection of the Website.
          </li>
          <li>
            disrupt or interfere with the security of, or otherwise cause harm
            to, the Website/Services/products, materials, systems resources, or
            gain unauthorized access to user accounts, passwords, servers or
            networks connected to or accessible through the
            Website/Services/products or any affiliated or linked sites.
          </li>
          <li>
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent Your affiliation with a person or entity.
          </li>
          <li>
            post, transmit or make available any material that contains viruses,
            trojan horses, worms, spyware, time bombs, cancelbots, or other
            computer programming routines, code, files or such other programs
            that may harm the Website/services, interests or rights of other
            users or limit the functionality of any computer software, hardware
            or telecommunications, or that may harvest or collect any data or
            personal information about other Users without their consent.
          </li>
          <li>
            Give any unauthorized access or distribute or reproduce the
            educational content or any other content which you may access on the
            Website to a third party. In the event, the Company becomes aware of
            any Users giving unauthorized access to any third party or
            distributing or reproducing any educational and related content on
            the Website to or any third-party channel, Company’s competitors or
            any media house, the Company reserves the right to suspend or
            terminate the said User’s account with immediate effect.
          </li>
        </ul>
      </span>
    ),
  },
  {
    p: (
      <span>
        8. This Agreement is published in compliance of, and is governed by the
        provisions of Indian law, including but not limited to:
        <ul className="list-disc">
          <li>the Indian Contract Act, 1872;</li>
          <li>the Information Technology Act, 2000 (the “IT Act”);</li>
          <li>
            the rules, regulations, guidelines and clarifications framed under
            the IT Act, including the Information Technology (Reasonable
            Security Practices and Procedures and Sensitive Personal
            Information) Rules, 2011 (the “SPI Rules”);
          </li>
          <li>
            the Information Technology (Intermediaries Guidelines) Rules, 2011
            (the “IG Rules”);{" "}
          </li>
          <li> the Consumer Protection Act, 1986</li>
        </ul>
      </span>
    ),
  },
];

const Submissionfinformation = [
  {
    p: "If You allow a third party, access to the Services through Your account, You shall ensure that the said third party is bound by and abides by the terms of this Agreement.",
  },
  {
    p: "Please refer to our Privacy Policy to know more about our policy with regards to collections, storage and use of personally identifiable information provided by You",
  },
  {
    p: "The Company authorizes You to access the Website solely for the purpose of using the Services.",
  },
  {
    p: (
      <span>
        4. You hereby authorize the Company to
        <ul className="list-disc">
          <li>render the Services provided by the Company</li>
          <li>use Your submitted information to contact You,</li>
          <li>
            maintain a record of Your queries, visit of the Website, and/or
            feedback, and
          </li>
          <li>
            use all submitted information by You in a manner as it may deem
            necessary
          </li>
        </ul>
      </span>
    ),
  },
  {
    p: "By submitting content on or through the Services (your “Material”), you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Material in any and all media or distribution methods (now known or later developed) and to associate your Material with you, except as described below. You agree that others may use Your Material in the same way as any other content available through the Services. Other users of the Services may fork, tweak and repurpose your Material in accordance with these Terms. If you delete your user account, your Material and name may remain available through the Services.",
  },
  {
    p: "For certain functions of the Website’s Services, such as reviewing and tracking Your use of the Services or to provide effective Service through the Website, You may be required to provide Your name, age, mobile or phone number, and/or address; without limiting the generality of foregoing, You are required to provide accurate and complete information.",
  },
  {
    p: (
      <span>
        7.The Company may, at any time and without having to serve any prior
        notice to You,
        <ul className="list-disc">
          <li>
            upgrade, update, change, modify, or improve the Website or a part
            thereof in a manner it may deem fit, and
          </li>
          <li>
            change the contents of Terms of Use and/or the Privacy Policy. It
            shall be Your responsibility, in such cases, to review the terms of
            the Agreement from time to time. Such change shall be made on the
            Website and shall reflect accordingly. The Company may also alter or
            remove any content from the Website without notice and without
            liability and that the Company shall not be liable for any loss of
            data as may be provided by you whatsoever. Any material changes
            shall be highlighted on the homepage.
          </li>
        </ul>
      </span>
    ),
  },
  {
    p: "The Company reserves the right, at its sole discretion, to suspend Your ability to use or access the Website (or a part thereof) or the Services at any time while the Company investigates complaints or alleged violations of these Agreement, or for any other reason. Further, it shall also have the ability to prohibit or restrict You from using the Website if the Company, in its opinion, feels that You are misusing the Website in any manner whatsoever",
  },
  {
    p: "The use of the Website is governed by the Company’s Privacy Policy which sets forth its standard practices regarding the collection, use and disclosure of personal information that it obtains about You in connection with the Services",
  },
];
const UserCovenants = [
  {
    p: "You must be 18 (eighteen) years of age to use the Services or submit any information on the Website. If You are below 18 (eighteen) years of age, You may only use the Services under the supervision of a parent or a legal guardian who agrees to be bound by these Terms. Persons who are “competent/capable” of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to register for Company’s products or Services. Persons who are minors, un-discharged insolvents etc. are not eligible to register for our products or Services. In the event a minor utilizes the Website and the related Services, it is assumed that he/she has obtained the consent of the legal guardian or parents and such use is made available by the legal guardian or parents. The Company will not be responsible for any consequence that arises as a result of misuse of any kind of the Website or any of Company’s products or Services that may occur by virtue of any person including a minor registering for the Services/products provided. By using the products or Services You warrant that all the data provided by You is accurate and complete and that student using the Website has obtained the consent of parent/legal guardian (in case of minors). The Company reserves the right to terminate Your subscription and / or refuse to provide You with access to the products or Services if it is discovered that You are under the age of 18 (eighteen) years and the consent to use the products or Services is not made by Your parent/legal guardian or any information provided by You is inaccurate. You acknowledge that the Company does not have the responsibility to ensure that You conform to the aforesaid eligibility criteria.",
  },
  {
    p: "You shall at all times, while using the Website comply with all applicable laws and regulations necessarily required to procure the Services from the Company and that the Company shall not be liable for any of Your unlawful acts while using the Website.",
  },
  {
    p: (
      <span>
        3.As mandated by Regulation 3(2) of the IG Rules, and to the extent of
        this Agreement, the Company hereby informs You that You are not
        permitted to host, display, upload, modify, publish, transmit, update or
        share any information wherever it may be required which:
        <ul className="list-disc">
          <li>
            Belongs to another person and to which You do not have any right;
          </li>
          <li>
            Is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, paedophilic, libellous, invasive of another’s privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating to or encouraging money laundering or gambling, or
            otherwise unlawful in any manner whatsoever;
          </li>
          <li>Harms minors in any way;</li>
          <li>
            Infringes any patent, trademark, copyright or other proprietary
            rights;
          </li>
          <li>Violates any law for the time being in force;</li>
          <li>
            Receives or misleads the addressee about the origin of such messages
            or communicates any information which is grossly offensive or
            menacing in nature;
          </li>
          <li>
            Impersonates or defames another person in a manner which could on
            grounds of religion, race, place of birth, residence, language,
            caste or community or any other ground whatsoever hurt his religious
            feelings;
          </li>
          <li>
            Contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource; and
          </li>
          <li>
            Threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognisable offence or
            prevents investigation of any offence or is insulting to any other
            nation.
          </li>
        </ul>
      </span>
    ),
  },
  {
    p: "The Company shall in order to increase its reachability across India and other countries, shall provide for referral scheme programs which shall allow its Users to opt for the scheme and avail such benefits as may be offered by the Company. Since it shall be an optional selective policy of the Company, the decision of the Company shall be final at all times and the same shall not be either disputed or challenged. It should be noted with abundant caution that no User, any partner on boarding or intending to work with the Company or other stake holder shall do business with the Company in anticipation of such referral benefits. Company shall not be held responsible if some User, student or any customer does business with the objective of getting referral benefits as in when paid by the Company which shall be entirely dependent on Company’s own discretion based on internal parameters and various demographic and economic factors.",
  },
  {
    p: (
      <span>
        5..You are also prohibited from:
        <ul className="list-disc">
          <li>
            Violating or attempting to violate the integrity or security of the
            Website;
          </li>
          <li>
            Transmitting any information on or through the Website that is
            disruptive or competitive to the provision of the services provided
            by the Company
          </li>
          <li>
            Intentionally submitting on the Website any incomplete, false or
            inaccurate information;
          </li>
          <li>
            Using any engine, software, tool, agent or other device or mechanism
            (such as spiders, robots, avatars or intelligent agents) to navigate
            or search the Website;
          </li>
          <li>
            Attempting to decipher, decompile, disassemble or reverse engineer
            any part of the Website;
          </li>
          <li>
            Copying or duplicating in any manner any of the information
            available from the Website; and
          </li>
          <li>
            Framing or hotlinking or deep linking any contents from the Website.
          </li>
          <li>
            The Company, upon obtaining knowledge by itself or having been
            brought to actual knowledge by an affected person in writing or
            through email about any such information as mentioned in clause 5.4
            above, shall be entitled to disable such information that is in
            contravention of clause 5.4, the Company shall be entitled to
            preserve such information and associated records for production to
            governmental authorities for investigation purposes
          </li>
        </ul>
      </span>
    ),
  },
];

const Thirdpartyinformation = [
  {
    p: "The Website may provide information regarding third party Website(s), affiliates or business partners and/or contain links to their Websites. Such information and links are provided solely for the purpose of Your reference. The Company is not endorsing the material on the Website, is not responsible for such errors and representation nor is it associated with it and You shall access these Websites at Your own risk. Further, it is up to You to take precautions to ensure that whatever links You select or software You download from the Website, is free of such items such as, but not limited to, viruses, worms, trojan horses, defects and other items of a destructive nature.",
  },
];

const Intellectualpropertyrights = [
  {
    p: "All the intellectual property used on the Website by the Company, service providers or any third party as the case may be shall remain the property of the Company, service provider or any other third party (as applicable). You agree not to circumvent, disable or otherwise interfere with security related features of the Website or features that prevent or restrict use or copying of any materials or enforce limitations on use of the Website or the materials therein. The Service is protected to the maximum extent permitted by copyright laws, other laws, and international treaties. Content displayed on or through the Services is protected by copyright as a collective work and/or compilation, pursuant to copyrights laws. The materials on the Website or otherwise may not be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted or transmitted in any form or by any means, including but not limited to, electronic, mechanical, photocopying, recording or other means.",
  },
];
const Liability = [
  {
    p: "The Company does not provide or make any representation, warranty or guarantee, express or implied about the Website or the Service",
  },
  {
    p: "The Company shall not be liable for any damages whatsoever, including, without limitation, damages for loss of use, data or profits, arising out of or in any way connected with the inability to use or performance of the Website or the Services. This shall extend to the loss suffered by You due to delay or inability to use or access the Website or the Services.",
  },
  {
    p: "The Company shall not be responsible or liable in any manner to You for any losses, damage, injuries or expenses incurred as a result of the use of the Website in any manner.",
  },
  {
    p: "It is agreed and acknowledged by You that to the fullest extent permitted by law, in no event shall the Company, its officers, directors, employees, or agents, be liable to you for any direct, indirect, incidental, special, punitive, losses or expenses or consequential damages whatsoever resulting from any (i) errors, mistakes, or inaccuracies of user content or any other content available on the Website, (ii) personal injury or property damage, of any nature whatsoever, resulting from your access to and use of our Website, (iii) any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein, (iv) any interruption or cessation of transmission to or from the Website, (iv) any bugs, viruses, trojan horses, or the like, which may be transmitted to or through our Website by any third party, and/or",
  },
  {
    p: "Any errors or omissions in any user content or any other content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the Website, whether based on warranty, contract, tort, or any other legal theory, and whether or not the Company is advised of the possibility of such damages",
  },
  {
    p: "We understand that, in some jurisdictions, warranties, disclaimers and conditions may apply that cannot be legally excluded, if that is true in your jurisdiction, then to the extent permitted by law, the Company limits its liability for any claims under those warranties or conditions to supplying you the Services through the Website.",
  },
  {
    p: "It is agreed and acknowledged by You that the Company shall not be liable for user content or the defamatory, offensive, or illegal conduct of any third party and that the risk of harm or damage from the foregoing rests entirely with You",
  },
];
const UseofELiNA = [
  {
    p: "All equipments/tools shared with you is the sole property Vimarshi Solutions Private Limited.",
  },
  {
    p: "It is to be used on the recommendation of the expert for the specified duration only.",
  },
  {
    p: "All equipment/tool received by you is to be used only for the child registered with the program",
  },
  {
    p: "Parents must at all times supervise use of equipment/tool by the child.",
  },
  {
    p: "Instructions for use of the equipment/tool to be followed as per experts’ advice only",
  },
];
const Indemnity = [
  {
    p: (
      <span>
        You hereby agree to indemnify and hold harmless the Company, its
        affiliates, officers, directors, employees, consultants, licensors,
        agents, and representatives from any and all third party claims, losses,
        liability, damages, and/or costs (including reasonable attorney fees and
        costs) arising from
        <ul>
          {" "}
          <li>
            Your access to or use of the Website in any fraudulent manner,
          </li>
          <li>
            {" "}
            Violation of the Agreement i.e. Terms of Use and Privacy Policy as
            agreed herein,
          </li>
          <li>
            Infringement, or infringement by any other user of Your account with
            the Company, and
          </li>
          <li>
            Infringement of any intellectual property or other right of any
            person or entity. The Company will notify You promptly of any such
            claim, loss, liability, or demand, and in addition to Your foregoing
            obligations, You agree to provide us with reasonable assistance, at
            Your expense, in defending any such claim, loss, liability, damage,
            or cost.
          </li>
        </ul>
      </span>
    ),
  },
];
const Severability = [
  {
    p: "If any provision of this Agreement is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability shall attach only to such provision or part of such provision and the remaining part of such provision and all other provisions of this Agreement shall continue to be in full force and effect.",
  },
];
const TermandTermination = [
  {
    p: "This Agreement will remain in full force and effect while You use the Website in any form or capacity.",
  },
  {
    p: "The Company reserves the right to terminate Your access to the Website and this Agreement in the event of breach of any terms contained in this Agreement, misrepresentation of information, any unlawful activity or is unable to verify or authenticate any information that you submit to the Company through the Website.",
  },
  {
    p: "Clauses under the headings Covenants, Liability, Indemnity, Intellectual Property, Dispute Resolution, and Notices shall continue and survive the termination of this Agreement. XIII. Dispute Resolution and Governing Law",
  },
  {
    p: " This Agreement and any contractual obligation between the Company and You will be governed by the laws of India, subject to the exclusive jurisdiction of courts at Chennai.",
  },
  {
    p: "All disputes will be subject to arbitration at Chennai in English by a single arbitrator appointed by You and the Company under the Arbitration and Conciliation Act, 1996",
  },
];
const Headings = [
  {
    p: "The headings and subheadings herein are included for convenience and identification only and are not intended to describe, interpret, define or limit the scope, extent or intent of this Agreement, the terms of Service or the right to use the Website by the Users contained herein or any other section or pages of the Website in any manner whatsoever.",
  },
];
const Disclaimer = [
  {
    p: "The Website is provided to You on “As Is” basis. The Company hereby disclaims all representation(s) and/or warranty(ies), either express or implied, including without limitation, warranties of fitness for particular purpose, title, non-infringement. In addition, thereto the Company makes no representations, warranties or guarantees that:",
  },
  {
    p: "This Website shall be free from any error, loss, destruction, damage, corruption, attack, viruses, interference, hacking, other security intrusion,",
  },
  {
    p: "The use or results of the use of the Website (including all of its content) is or will be accurate, reliable, current, and/or uninterrupted,",
  },
  {
    p: "The Company disclaims any liability relating thereto.",
  },
];
const Notices = [
  {
    p: "All notices and communications shall be in writing, in English and shall deemed given if delivered personally or by commercial messenger or courier service, or mailed by registered or certified mail (return receipt requested) or sent via email/ facsimile, with due acknowledgment of complete transmission to the following address: C1-301, Pelican Nest, Creek Street, Okkium Thoraipakkam, Chennai – 600097.",
  },
  {
    p: "Questions or any feedback about the Website or the Services may be directed to the Company and You may contact us at email address : info@elinaservices.in",
  },
];
const Termscondition = () => {
  return (
    <div className="md:mx-[10%] mx-[2%] helloPrivacy text-justify">
      <h1 className="flex justify-center text-5xl font-black text-center text-white bg-pink-600 underline py-3">
        Terms & Conditions
      </h1>
      <section>
        <h1>Introduction</h1>
        <ul className="list-decimal">
          {Introduction.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Website Use</h1>
        <ul className="list-decimal">
          {WebsiteUse.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Submission of information</h1>
        <ul className="list-decimal">
          {Submissionfinformation.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>User Covenants</h1>
        <ul className="list-decimal">
          {UserCovenants.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Third party information</h1>
        <ul className="list-decimal">
          {Thirdpartyinformation.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>

      <section>
        <h1>Intellectual property rights</h1>
        <ul className="list-decimal">
          {Intellectualpropertyrights.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Liability</h1>
        <ul className="list-decimal">
          {Liability.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Use of ELiNA equipment / tools</h1>
        <ul className="list-decimal">
          {UseofELiNA.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Indemnity</h1>
        <ul className="list-decimal">
          {Indemnity.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Severability</h1>
        <ul className="list-decimal">
          {Severability.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Term and Termination</h1>
        <ul className="list-decimal">
          {TermandTermination.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Headings</h1>
        <ul className="list-decimal">
          {Headings.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Disclaimer</h1>
        <ul className="list-decimal">
          {Disclaimer.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
      <section>
        <h1>Notices</h1>
        <ul className="list-decimal">
          {Notices.map((item, index) => (
            <li key={index}>{item.p}</li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Termscondition;
