import React from "react";

const RefundPolicy = () => {
  return (
    <>
      <div className="md:mx-[10%] mx-[2%]  helloPrivacy text-justify">
        <h1 className="flex justify-center text-5xl font-black text-white bg-pink-600 underline py-3">
          Refund Policy
        </h1>
        <section>
          <h1>Information to the Parent :</h1>
          <ul className="list-decimal">
            <li>Information to the Parent</li>
            <li>
              You shall ensure that the Information provided by You in the
              Website’s registration form or otherwise is complete, accurate and
              up to date.
            </li>
            <li>
              If You provide any Information that is untrue, inaccurate, not
              current, or incomplete (or becomes untrue, inaccurate, not current
              or incomplete), or the Company has reasonable grounds to suspect
              that such Information is untrue, inaccurate, not current or
              incomplete, the Company has the right to refuse or cancel the
              Enrollment of Your child.
            </li>
          </ul>
        </section>
        <section>
          <h1>Obligations of the Parent :</h1>
          <ul className="list-decimal">
            <li>The fee is payable in advance for the program duration.</li>
            <li>
              You shall pay the tenure fees, as prescribed, in the designated
              account mentioned on the invoice. If You default in payment of the
              fees for a successive period of seven (7) days, the Enrollment of
              the student shall stand cancelled and Your child will not be
              entitled to receive the benefit(s) under the ELiNA’s Program(s).
            </li>
            <li>
              You shall not have any claim, whatsoever, on the Content provided
              by the Company and shall not acquire any right, title, or interest
              in or to the Content.
            </li>
            <li>
              Where a parent/ student is decimalontinuing with ELiNA’s Program,
              access to the account, app etc shall be suspended.
            </li>
            <li>
              A meeting session is defined as NO SHOW if the parent has not
              joined the session within 10 minutes of the scheduled start time,
              and there is no intimation for the same. The session may be
              cancelled, without any compensatory session and is, fully
              chargeable.NO SHOWs are not entitled to compensatory sessions
            </li>
            <li>
              In case technical issues are experienced during the meetings,
              ELiNA will arrange for a compensatory session if the parent had
              reached out to the help desk prior to or during the session.
            </li>
          </ul>
        </section>
        <section>
          <h1>Refund Policy</h1>
          <ul className="list-decimal">
            <li>
              A refund request will be deemed valid only if it is made through
              an email to anvitha@elinaservices.in. The refund will be made into
              the source account
            </li>
            <li>
              If an assessment is done then there is no refund once the activity
              list is scheduled by the expert. For example:
            </li>
            <li>
              If you request for an assessment and you seek for refund before
              the scheduled date and time of sending the guidebook, then in that
              case full refund will be provided.
            </li>
            <li>
              If you request for an assessment and are not providing requested
              information at the scheduled duration without any information then
              in that case no refund will be provided
            </li>
            <li>
              If you request for an assessment and are not providing requested
              information at the scheduled duration without any information then
              in that case no refund will be provided
            </li>
            <li>
              If you request for an assessment and seek refund after partial
              completion of the assessment, refund will not be provided.
            </li>
            <li>There is no concept of partial refund of assessment.</li>
            <li>
              If there is a need for reschedule of meetings, upto 2 reschedule
              attempts are permitted as per availability of the Navigators.
            </li>
            <li>
              We will request you to share your bank account details where it
              can transfer the refund amount, as may be required, to process the
              refund. Once we have all the required information, we will process
              your refund for unused classes, within 7 working days.*Refunds
              timelines and taxes (if applicable) depend on Bank turnaround
              times and RBI guidelines.
            </li>
          </ul>
        </section>
      </div>
    </>
  );
};

export default RefundPolicy;
