import React, { useEffect, useRef, useState } from "react";
import NavBar from "../../../components/NavBar";
import Hero from "../../../components/home/Hero";
import WhatWeDo from "../../../components/home/WhatWeDo";
import OurVision from "../../../components/home/OurVision";
import Video from "../../../components/home/Video";
import Footer from "../../../components/Footer";
import Intern from "../../../components/home/Intern";
import OutComes from "../../../components/home/OutComes";
import event from "../../../images/event.png";
import { NavLink } from "react-router-dom";
import Champion from "../../../components/home/Champion";
// import ScrollToTop from "react-scroll-to-top";
// import ScrollToTop from "react-scroll-to-top";
// import Events from "../../../components/home/Events";
// import Chatbot from "../../../components/chatbot/Chatbot";
// import bot from "../../../images/bot.png";
import Cookiespages from "../../../components/cookies/cookies";
import ScrollToTop from "react-scroll-to-top";
const HeroContent = [
  {
    HeroButtonText: "ADHD",
  },
  {
    HeroButtonText: "Autism",
  },
  {
    HeroButtonText: "Dyslexia",
  },
  {
    HeroButtonText: "Dyspraxia",
  },
  {
    HeroButtonText: "Dyscalculia",
  },
  {
    HeroButtonText: "Learning Disability",
  },
  {
    HeroButtonText: "Intellectual Disability",
  },
];

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [modelOpen, setModelOpen] = useState(false);
  const modalRef = useRef();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setModelOpen(false);
    }
  };

  useEffect(() => {
    if (modelOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modelOpen]);

  // function getCookieValue(cookieName) {
  //   const name = cookieName + "=";
  //   const decodedCookie = decodeURIComponent(document.cookie);
  //   const cookieArray = decodedCookie.split("; ");

  //   for (let cookie of cookieArray) {
  //     if (cookie.indexOf(name) === 0) {
  //       return cookie.substring(name.length);
  //     }
  //   }
  //   return null; // Return null if the cookie is not found
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const [showChatbot, setShowChatbot] = useState(false);

  // async function toggleChatbot(e) {
  //   // alert(document.cookie)
  //   setShowChatbot(!showChatbot);
  //   try {
  //     e.preventDefault();
  //     const res = await fetch(
  //       "http://192.168.0.103:5000/api/insertNewSession",
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           sessionId: sessionStorage.getItem("sessionId"),
  //           ipAddress:  getCookieValue("ip") ,
  //         }),
  //       }
  //     );
  //     const data = await res.json();
  //     console.log(data);
  //   } catch (e) {
  //     console.log("Session ID was not created");
  //   }
  // }

  // const toggleChatbot = () => {
  //   setShowChatbot(!showChatbot);
  // };
  const YourCustomArrowIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="white" // The arrow color is set to white
    >
      <path d="M12 2L5 9h4v9h6V9h4l-7-7z" />
    </svg>
  );
  return (
    <>
      <div className="relative">
        <Hero HeroContent={HeroContent} />
        <WhatWeDo />
        <OurVision />
        <Video />
        <Champion />
        <OutComes />
        <Intern />
        <ScrollToTop
          smooth
          style={{
            backgroundColor: "#0048ff",
            display: "flex",
            borderRadius: "20px",
            justifyContent: "center",
            alignItems: "center",
            bottom: "70px",
            right: "23px",
            width: "40px",
            height: "40px",
          }}
          component={<YourCustomArrowIcon />}
        />
        {/* <div className="fixed bottom-4 right-4">
          <button
            onClick={toggleChatbot}
            className="p-2 bg-blue-700 rounded-full hover:bg-blue-700"
          >
            <img src={bot} className="w-8 h-8" alt="bot" />
          </button>
        </div> */}
      </div>

      {/* {showChatbot && (
        <div className="fixed bottom-20 right-4  shadow-lg  rounded-lg z-50">
          <Chatbot
            setShowChatbot={setShowChatbot}
            showChatbot={showChatbot}
            random={sessionStorage.getItem("sessionId")}
          />
        </div>
      )} */}
    </>
  );
};

export default Home;
