import { Outlet, NavLink } from "react-router-dom";
import Bookslide from "./Bookslide";
import BreadCrumbs from "../reuseable/BreadCrumbs";

const Content = () => {
  return (
    // <Bookslide/>
    <>
      <div className="md:ml-0 md:pt-0">
        <BreadCrumbs />
      </div>
      <div className="p-5 HeroBg">
        <span className="flex justify-center items-center text-5xl font-extrabold mb-10 text-blue-700">
          FAQ
        </span>
        <div className="flex flex-col items-center">
          <div className="p-4 w-full max-w-3xl justify-evenly flex rounded-lg  text-center md:text-xl text-md lg:text-2xl font-bold mb-4">
            <NavLink
              to="/faq/"
              end
              className={({ isActive }) =>
                isActive ? "text-pink-500" : "text-black"
              }
            >
              Parents
            </NavLink>
            <NavLink
              to="/faq/professional"
              className={({ isActive }) =>
                isActive ? "text-pink-500" : "text-black"
              }
            >
              Professional
            </NavLink>
            <NavLink
              to="/faq/school"
              className={({ isActive }) =>
                isActive ? "text-pink-500" : "text-black"
              }
            >
              School
            </NavLink>
            <NavLink
              to="/faq/intern"
              className={({ isActive }) =>
                isActive ? "text-pink-500" : "text-black"
              }
            >
              Intern
            </NavLink>
          </div>
        </div>
        <Outlet />
      </div>
    </>
  );
};

export default Content;
