import React from "react";
import BreadCrumbs from "../reuseable/BreadCrumbs";

const EventsHero = () => {
  const EventWinners = [
    {
      img: "",
      name: "Dinesh",
      eventname: "Dance",
      description:
        "He got 1st prize in Solo dance event conducted in Elina cultural event",
    },
    {
      img: "",
      name: "Dinesh",
      eventname: "Dance",
      description:
        "He got 1st prize in Solo dance event conducted in Elina cultural event",
    },
    {
      img: "",
      name: "Dinesh",
      eventname: "Dance",
      description:
        "He got 1st prize in Solo dance event conducted in Elina cultural event",
    },
    {
      img: "",
      name: "Dinesh",
      eventname: "Dance",
      description:
        "He got 1st prize in Solo dance event conducted in Elina cultural event",
    },
    {
      img: "",
      name: "Dinesh",
      eventname: "Dance",
      description:
        "He got 1st prize in Solo dance event conducted in Elina cultural event",
    },
  ];

  return (
    <>
      <div className="md:ml-0 md:pt-0">
        <BreadCrumbs />
      </div>
      <div className="eventBg relative">
        <div>
          <div className="flex flex-col lg:gap-5 items-center text-white px-5 lg:px-[10%] lg:pt-[10%] lg:pb-[6%]">
            <div className="lg:bg-black-bg lg:p-10 md:p-5 my-24 lg:rounded-3xl">
              <h1 className="text-4xl lg:text-[4.75rem] text-white py-5 md:py-0 text-center font-black">
                Events
              </h1>
            </div>
            {/* <div className="w-full hidden lg:flex absolute bottom-6  justify-center">
              <div className="px-6 py-4 w-full max-w-6xl bg-white text-black flex items-center rounded-lg justify-evenly">
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 text-center py-1 font-bold">
                  ADHD
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 text-center py-1 font-bold">
                  Autism
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 text-center py-1 font-bold">
                  Learning
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 text-center py-1 font-bold">
                  Disabilities
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 text-center py-1 font-bold">
                  Dyslexia
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 text-center py-1 font-bold">
                  Dyspraxia
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 text-center py-1 font-bold">
                Dyscalculia
                </div>
                <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/6 text-center py-1 font-bold">
                Intellectual Disability
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsHero;
