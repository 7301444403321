import React from "react";

const StoryOfElina = ({ storyOfElina }) => {
  return (
    <div id="scroll">
      {storyOfElina.map((item, index) => (
        <div
          key={index} // Add a key prop to help React identify which items have changed
          className={`p-16 lg:h-fit flex flex-col lg:flex-row ${
            item.ImgDirection === "left" ? "" : "lg:flex-row-reverse"
          } justify-center gap-10 lg:gap-[5%] px-8 lg:px-20 ${
            item.ImgDirection === "left" ? "HeroBg" : "HeroBg2"
          }`}
        >
          <div className="w-full lg:w-1/2 flex justify-center">
            <img
              className={`rounded-3xl w-full md:w-[630px] md:h-[400px] ${
                item.ImgDirection === "left" ? "lg:ml-auto" : ""
              }`}
              src={item.src}
              alt={item.p}
            />
          </div>
          <div
            className={`w-full lg:w-1/2 px-0 md:px-10 lg:px-0 font-medium text-base text-justify md:text-lg lg:text-xl ${
              item.ImgDirection === "left" ? "rightToLefta" : "leftToRighta"
            }`}
          >
            <p>{item.p}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StoryOfElina;
