import React from 'react'
import { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa'; 
const Parentsfaq = () => {
    const faqItems = {
        parents: [
          {
            id: "1",
            question:
              "What programs does Elina offer for children with special needs?",
            answer:
              "Elina offers the SAIL Program, which includes comprehensive assessments and personalized recommendations, and the CoMPASS Program, which provides integrated support through collaboration among schools, families, and professionals.",
          },
          {
            id: "2",
            question: "How do I enroll my child in Elina's programs?",
            answer:
              "You can begin the enrollment process by filling out the registration form on our website. Following this, we will schedule a consultation meeting to discuss your child's specific needs and recommend appropriate programs",
          },
          {
            id: "3",
            question: "What kind of support can I expect from Elina?",
            answer:
              "We provide personalized learning plans, tailored strategies, and continuous support for your child. This includes regular monitoring, communication, and adjustments to interventions as needed.",
          },
          {
            id: "4",
            question: "How does Elina coordinate with my child’s school?",
            answer:
              "Elina collaborates closely with your child's school through the CoMPASS Program, ensuring that all interventions are aligned and effective. We work with teachers and other school professionals to support your child’s development.",
          },
          {
            id: "5",
            question: "Does Elina provide direct therapy?",
            answer:
              "Elina does not provide direct therapy. Instead, we guide and coordinate the efforts of various professionals, including therapists, educators, and doctors. Our role is to ensure that all interventions are effective and aligned with the child's needs.",
          },
          {
            id: "6",
            question: "Is Elina a school or a therapy center?",
            answer:
              "No, Elina is not a school or a therapy center. We are a coordinating body that plays a crucial role in ensuring all the interventions and support systems work together effectively for the child's benefit. Our focus is on facilitating collaboration and providing comprehensive guidance.",
          },
          {
            id: "7",
            question:
              "What if I feel overwhelmed by the many options available for my child's support?",
            answer:
              "It is common for parents to feel confused by the numerous options available for their child's support. Elina helps streamline and prioritize these options, providing clear guidance on the most effective interventions and resources. We work closely with you to ensure that your child's needs are met in the best possible way.",
          },
          {
            id: "8",
            question: "What are the benefits of the SAIL Program?",
            answer:
              "The SAIL Program helps identify your child's strengths and challenges, providing a holistic view of their needs. It includes comprehensive assessments and personalized recommendations to enhance their learning and development.",
          },
        ],}
        const [openId, setOpenId] = useState(null);
        const toggleAccordion = (id) => {
            setOpenId((prevId) => (prevId === id ? null : id));
        };
  return (
    <div className="flex flex-col items-center mb-20">
                <div className='p-4 w-full max-w-3xl bg-pink-500 rounded-lg shadow-lg text-center text-xl lg:text-3xl md:text-2xl font-bold text-white mb-4'>
                    For Parents
                </div>
                {faqItems.parents.map((data) => (
                  <div
                        key={data.id}
                        className="w-full max-w-3xl p-5 mb-4 bg-white rounded-lg shadow-lg"
                    >
                        <div
                            className="flex justify-between items-center cursor-pointer text-lg font-semibold"
                            onClick={() => toggleAccordion(data.id)}
                        >
                            <span className='md:text-xl text-base'>{data.question}</span>
                            <span>
                                {openId === data.id ? (
                                    <FaMinus className="text-pink-500" /> 
                                ) : (
                                    <FaPlus className="text-pink-500" />
                                )}
                            </span>
                        </div>
                        {openId === data.id && (
                            <p className="mt-3 text-grey-600  lg:text-lg md:text-lg text-base rounded-lg p-2 border-l-4 border-pink-500">{data.answer}</p>
                        )}
                    </div>
                ))}
            </div>
  )
}

export default Parentsfaq