import React from "react";
import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
const Professionalfaq = () => {
  const faqItems = {
    professional: [
      {
        id: "1",
        question: "What is the role of professionals in Elina’s programs?",
        answer:
          "Professionals, including special educators, therapists, doctors, and counselors, collaborate with Elina to provide comprehensive support for children. They contribute their expertise to assessments, interventions, and continuous support through our programs.",
      },
      {
        id: "2",
        question: "How do I join Elina's network of professionals?",
        answer:
          "To join our network, fill out the registration form on our website and schedule a consultation meeting. We will discuss your areas of expertise and potential roles in our programs.",
      },
      {
        id: "3",
        question: "What are the benefits of collaborating with Elina?",
        answer:
          "Professionals benefit from targeted referrals, a holistic view of the child's needs, effective interventions, enhanced visibility, access to the ISMS tool for seamless communication, and continuous professional development.",
      },
      {
        id: "4",
        question: "How does Elina ensure coordinated care for children?",
        answer:
          "Elina acts as a central coordinating figure, linking the efforts of various professionals. We use the ISMS tool to facilitate communication and tracking, ensuring that all interventions are aligned and focused on the child's best interests.",
      },
      {
        id: "5",
        question: "What opportunities are there for professional development?",
        answer:
          "Elina offers training sessions, workshops, and resources to help professionals stay updated with best practices and new strategies in supporting neurodiverse children.",
      },
    ],
  };
  const [openId, setOpenId] = useState(null);
  const toggleAccordion = (id) => {
    setOpenId((prevId) => (prevId === id ? null : id));
  };
  return (
    <div className="flex flex-col items-center mb-20">
      <div className="p-4 w-full max-w-3xl bg-pink-500 rounded-lg shadow-lg text-center text-xl lg:text-3xl md:text-2xl font-bold text-white mb-4">
        For Professionals
      </div>
      {faqItems.professional.map((data) => (
        <div
        key={data.id}
        className="w-full max-w-3xl p-5 mb-4 bg-white rounded-lg shadow-lg"
    >
        <div
            className="flex justify-between items-center cursor-pointer text-lg font-semibold"
            onClick={() => toggleAccordion(data.id)}
        >
            <span className='md:text-xl text-base'>{data.question}</span>
            <span>
                {openId === data.id ? (
                    <FaMinus className="text-pink-500" /> 
                ) : (
                    <FaPlus className="text-pink-500" />
                )}
            </span>
        </div>
        {openId === data.id && (
            <p className="mt-3 text-grey-600  lg:text-lg md:text-lg text-base rounded-lg p-2 border-l-4 border-pink-500">{data.answer}</p>
        )}
    </div>
      ))}
    </div>
  );
};

export default Professionalfaq;
