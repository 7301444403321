import React from "react";
import eventposter from "../../images/events/eventposter.png";
const Currentevent = () => {
  const CurrentEvent = [
    {
      img: eventposter,
    },
  ];

  return (
    <div className="lg:my-16  justify-center flex flex-col">
      <div className="text-black text-center my-12 px-2 md:px-0 lg:px-0">
        <p className="text-3xl md:text-5xl lg:text-6xl font-bold text-indigo-600">
          Welcome to Elina's events page
        </p>
        <p className="text-xl md:text-xl lg:text-xl font-normal lg:mt-3 sm:mt-3 mt-2 text-gray-500 lg:px-32 md:px-20 px-4">
          Here, you’ll find information about our upcoming workshops, seminars,
          and community events designed to support and empower families and
          professionals involved in the special needs community.
        </p>
      </div>
      <div>
        {/* <div className="absolute bottom-24 "><button className="text-2xl font-bold px-3 py-2 rounded-lg bg-purple-900">REGISTER NOW</button></div> */}
        {CurrentEvent.map((items, index) => (
          <div
            key={index}
            className="items-center flex justify-center relative"
          >
            <img
              className="lg:w-1/2 md:w-2/3 w-4/5 h-fit"
              src={items.img}
              alt=""
            ></img>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Currentevent;
