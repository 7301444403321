import React from "react";
import "../../index.css";
import { useNavigate } from "react-router-dom";
const Events = () => {
  const navigate = useNavigate();
  const EventDetails = [
    {
      eventname: "",
      description:
        "Stay connected with us to know more about our upcoming webinars and events shortly",
      venue: "",
      time: "",
      date: "",
      location: "",
    },
  ];

  return (
    <div
      onClick={() => navigate("/events")}
      className="w-full h-10 justify-center cursor-pointer bg-pink-600 items-center flex text-white"
    >
      <div className="scroll w-full">
        <div className="RightToLeft">
          {EventDetails.map((items) => (
            <>
              <p className="font-semibold lg:text-xl md:text-lg text-base">
                {items.description}
              </p>
              <p className="font-semibold lg:text-xl md:text-lg text-base">
                {items.eventname}
              </p>
              <p className="font-semibold lg:text-xl md:text-lg text-base">
                {items.venue}
              </p>
              <p className="font-semibold lg:text-xl md:text-lg text-base">
                {items.time}
              </p>
              <p className="font-semibold lg:text-xl md:text-lg text-base">
                {items.date}
              </p>
              <p className="font-semibold lg:text-xl md:text-lg text-base">
                {items.location}
              </p>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
