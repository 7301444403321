import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  const [content, setContent] = useState("");

  const dbConnection = async (e) => {
    setLoading(true);
    setSubmitting(null);

    e.preventDefault();
    const data = {
      emailid: e.target.email.value,
    };

    try {
      const response = await axios.post(
        // "https://onlineappointment.onrender.com/api/newsletters/storedata",
        // "http://183.83.188.205:60162/api/newsletters/storedata",
        "https://elinaservices.com:60162/api/newsletters/storedata",

        data
      );

      response.data === "User Already Subscribed"
        ? toast.info(e.target.email.value + " already subscribed!", {
            position: "top-center",
            className: "text-white bg-blue-600",
          })
        : setSubmitting("success");
      setContent(e.target.email.value);

      e.target.reset();
    } catch (err) {
      setSubmitting("error");
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (submitting === "success") {
      toast.success("Thanks for subscribing", {
        position: "top-center",
        className: "text-white",
      });
    } else if (submitting === "error") {
      toast.error(
        "There was an error submitting the form. Please contact admin.",
        {
          position: "top-center",
          className: "text-red-500 bg-white",
        }
      );
    }
  }, [submitting]);

  return (
    <div className="bg-blue-600 w-[90%] lg:w-[90%] items-center mx-auto rounded-3xl m-5 blockquote ">
      <div className="flex flex-col gap-10 py-10 w-full">
        <h1 className="text-4xl lg:text-6xl text-white font-black text-center">
          Sign up for our Newsletter
        </h1>
        {loading ? (
          <div className="flex justify-center items-center font-bold text-lg text-white">
            Submitting...
          </div>
        ) : (
          <form
            onSubmit={dbConnection}
            className="md:max-w-sm max-w-xl md:mx-auto flex flex-col gap-10 py-10 w-full"
          >
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 16"
                >
                  <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                  <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                </svg>
              </div>
              <input
                type="email"
                name="email"
                id="email-address-icon"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-200 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@gmail.com"
                required
              />
            </div>
            <button
              type="submit"
              className="w-[60%] md:w-[60%] mx-auto py-2 text-xl bg-white text-pink-600 border-white hover:border-white hover:text-white"
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default SignUp;
