import React, { useState, useEffect } from "react";
import dummy from "../../images/gopika.jpeg";
const CarouselContent = [
  {
    img: dummy,
    details: "",
    name: " Ms. Gopica, ",
    role: "Yoga therapist",
    award: "Meet our therapist of the month!",
    awarddescription:
      "We are proud to feature Ms. Gopica, a dedicated Yoga Therapist, whose exceptional involvement in the CoMPASS Program has greatly impacted Mamathi’s progress.",
  },
  {
    img: dummy,
    details: "",
    name: "Ms. Gopica",
    role: "Yoga therapist",
    award: "Celebrating excellence and dedication!",
    awarddescription:
      "Ms. Gopica consistently provided prompt, insightful inputs and never missed a meeting. Her dedication to Mamathi’s well-being through tailored yoga sessions has been instrumental in supporting her physical and emotional growth.",
  },
  {
    img: dummy,
    details:
      "Thank you, Ms. Gopica, for your  commitment and significant contributions to Elina and the children we serve.",
    name: "Ms. Gopica",
    role: "Yoga therapist",
    award: "Key role in CoMPASS ",
    awarddescription:
      "Ms. Gopica’s active participation and collaboration with other specialists have  made her a valued member of our team, helping to create a holistic and supportive environment for Mamathi.",
  },
];

const Champion = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically move to the next slide every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000); // 5 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === CarouselContent.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? CarouselContent.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative lg:max-w-full mb-20 overflow-hidden">
      <p className="text-4xl p-5 text-center flex justify-center mt-24 mb-10 md:text-5xl lg:text-6xl font-black text-blue-700">
        Elina's Community Spotlight
      </p>
      <div className="flex items-center justify-center lg:gap-20">
        <button className="bg-transparent border-none z-10" onClick={prevSlide}>
          <svg
            className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 19l-7-7 7-7"
            ></path>
          </svg>
        </button>
        <div className="max-w-full md:max-w-3xl lg:max-w-5xl overflow-hidden">
          <div
            className="flex transition-transform duration-700 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {CarouselContent.map((item, index) => (
              <div
                key={index}
                className="w-full flex-shrink-0 flex flex-col items-center"
              >
                <div className="grid grid-cols-10 w-full">
                  <div className=" Popper col-span-10 md:col-span-4 lg:col-span-3 bg-pink-600 gap-14 p-4 md:p-6 lg:p-8">
                    <div className="flex justify-around flex-col h-full">
                      {/* <div className="flex flex-col text-center lg:mb-6 lg:mt-6">
                        <img className="w-30 h-30" src={elinaservices} alt=""></img>
                      </div> */}
                      <div className="w-full flex justify-center lg:mb-4">
                        <img
                          className="rounded-full w-32 h-32 md:w-36 md:h-36 lg:w-44 lg:h-44"
                          src={item.img}
                          alt="avatar"
                        />
                      </div>
                      <div className="text-center text-2xl md:text-xl lg:text-3xl text-white font-bold lg:mb-2">
                        <p>{item.name}</p>
                      </div>
                      <div className="text-white text-center text-xl md:text-lg lg:text-xl lg:mb-8 font-semibold">
                        <p>{item.role}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-10 md:col-span-6 lg:col-span-7 HeroBg2">
                    <div className="p-6 md:p-8 lg:p-14">
                      <div className="text-2xl md:text-4xl lg:text-5xl text-blue-700 md:pb-6 pb-4 lg:pb-8 font-bold">
                        <p>{item.award}</p>
                      </div>
                      <div className="text-xl md:text-2xl lg:text-2xl md:pb-4 pb-2 lg:mb-6 font-normal ">
                        <p>{item.awarddescription}</p>
                      </div>
                      <div className="text-xl font-normal text-gray-500">
                        <p>{item.details}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="bg-transparent border-none z-10" onClick={nextSlide}>
          <svg
            className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </button>
      </div>
      <div className="flex justify-center mt-8">
        {CarouselContent.map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`h-2 w-2 mx-4 border-none rounded-full ${
              index === currentIndex
                ? "bg-gray-700 border"
                : "bg-gray-400 hover:bg-gray-400"
            }`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Champion;
