import React from "react";
import { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
const Schoolfaq = () => {
  const faqItems = {
    school: [
      {
        id: "1",
        question: "How can my school collaborate with Elina?",
        answer:
          "Schools can collaborate with Elina by filling out the registration form on our website and scheduling a consultation meeting. We will discuss your school's specific needs and how our programs can support your students.",
      },
      {
        id: "2",
        question: "What programs are available for schools?",
        answer:
          "Elina offers the SAIL Program for detailed student assessments and the CoMPASS Program for continuous collaboration and integrated support. We also provide teacher training and professional development.",
      },
      {
        id: "3",
        question: "How does Elina support teachers and educators?",
        answer:
          "We offer professional development workshops, training sessions, and resources to equip teachers with inclusive teaching strategies, behavior management techniques, and the use of educational technology.",
      },
      {
        id: "4",
        question: "Can Elina work with students with various needs?",
        answer:
          "Yes, Elina's programs are designed to support children with ADHD, Autism, Learning Disabilities, and other neurodiverse conditions. We tailor our interventions to meet the specific needs of each student.",
      },
      {
        id: "5",
        question:
          "How does Elina ensure effective communication between school staff and parents?",
        answer:
          "Through the CAMPASS Program, Elina facilitates regular communication and collaboration between school staff, parents, and other professionals. We ensure that all stakeholders are informed and involved in the child's development.",
      },
      {
        id: "6",
        question: "What value does Elina add to our school’s support system?",
        answer:
          "Elina acts as a bridge, ensuring timely intervention to prevent any future challenges from escalating. We increase confidence levels among educators by providing training and support, and we ensure that every child receives holistic and inclusive support. Our coordinated approach helps schools create a supportive learning environment for all students.",
      },
    ],
  };
  const [openId, setOpenId] = useState(null);
  const toggleAccordion = (id) => {
    setOpenId((prevId) => (prevId === id ? null : id));
  };
  return (
    <div className="flex flex-col items-center mb-20">
      <div className="p-4 w-full max-w-3xl bg-pink-500 rounded-lg shadow-lg text-center text-xl lg:text-3xl md:text-2xl font-bold text-white mb-4">
        For Schools
      </div>
      {faqItems.school.map((data) => (
        <div
        key={data.id}
        className="w-full max-w-3xl p-5 mb-4 bg-white rounded-lg shadow-lg"
    >
        <div
            className="flex justify-between items-center cursor-pointer text-lg font-semibold"
            onClick={() => toggleAccordion(data.id)}
        >
            <span className='md:text-xl text-base'>{data.question}</span>
            <span>
                {openId === data.id ? (
                    <FaMinus className="text-pink-500" /> 
                ) : (
                    <FaPlus className="text-pink-500" />
                )}
            </span>
        </div>
        {openId === data.id && (
            <p className="mt-3 text-grey-600  lg:text-lg md:text-lg text-base rounded-lg p-2 border-l-4 border-pink-500">{data.answer}</p>
        )}
    </div>
      ))}
    </div>
  );
};
export default Schoolfaq;
