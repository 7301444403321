import { useEffect, useState } from "react";
import React from "react";
import qrcode from "../../images/payment_QR.jpg";
import downarrow from "../../images/downarrow.png";

const Getintouch = () => {
  const [isactive, setIsactive] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="w-full">
        <div className="text-center">
          <p className="lg:text-5xl md:text-4xl text-2xl font-bold text-pink-500">
            ELiNA connect
          </p>
        </div>
        <div className="text-justify lg:px-20 px-5 md:px-10 py-3">
          <p className="text-base lg:text-xl">
            "Elina Connect" is your direct link to expert guidance and support.
            Schedule a 1-hour appointment with our specialists, who understand
            your child’s unique needs, and receive personalized advice on the
            next steps for early intervention. A consultation fee of ₹1500
            applies for this session, ensuring you get the tailored guidance you
            need to move forward with confidence. Your booking will be confirmed
            once the payment is successfully completed. Thank you for your
            understanding and support.
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full lg:px-20 px-5 md:px-10 ">
        {/* {/ Left Section: Bank Details Accordion /} */}
        <div className="w-full lg:w-1/3 flex flex-col gap-5 p-4">
          <div className="bg-pink-500 text-white p-4 rounded-md w-full text-start h-fit">
            {/* {/ Accordion Header /} */}
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => setIsactive(!isactive)}
            >
              <p className="font-bold text-xl md:text-2xl lg:text-4xl mb-2">
                Bank Details
              </p>
              <img
                src={downarrow}
                alt="Toggle"
                className={`transition-transform duration-300 ${
                  isactive ? "rotate-180" : "rotate-0"
                } w-6 h-6`}
              />
            </div>

            {/* {/ Accordion Content /} */}
            <div
              className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                isactive ? "max-h-96" : "max-h-0"
              }`}
            >
              <div className="flex flex-col w-full p-4 rounded-md">
                <div className="w-full h-fit flex flex-col items-start justify-start mb-4 text-base md:text-lg lg:text-xl font-semibold gap-2">
                  <p>Account Number: 123456789011</p>
                  <p>IFSC Code: HDFC0000123</p>
                  <p>Amount: 1500rs</p>
                  <p>UPI ID: 9841050686@pz</p>
                </div>

                <div className="w-40 h-40 md:w-52 md:h-52">
                  <img
                    src={qrcode}
                    alt="qrcode"
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {/ Right Section: Iframe /} */}
        <div className="w-full lg:w-2/3 flex flex-col items-start justify-start p-4">
          <div className=" w-full">
            <iframe
              width="100%"
              height="500px"
              src="https://talenttakeaways.zohobookings.in/portal-embed#/224563000000136002"
              frameBorder="0"
              allowFullScreen=""
              title="Elinaconnect"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Getintouch;
