import React from "react";
import BreadCrumbs from "../reuseable/BreadCrumbs";

const ProfessionalHero = () => {
  return (
    <>
      <BreadCrumbs />

      <div className="professionalBg h-screen bg-black-bg lg:bg-black-bg1">
        <div className="flex items-center justify-center h-full px-4 lg:px-10 py-10 lg:py-20">
          <div className="bg-black-bg lg:bg-black-bg p-6 lg:p-10 rounded-3xl flex flex-col justify-center items-center text-center gap-8 lg:gap-12">
            <p className="text-white text-3xl md:text-5xl lg:text-7xl font-black">
              Guided by compassion, driven by expertise
            </p>
            <p className="text-lg md:text-xl font-normal text-white px-4 lg:px-8">
              Welcome to a place where professional expertise seamlessly blends
              with heartfelt compassion. At ELiNA, we believe in a holistic
              approach to child development, partnering with therapists to
              empower every child’s journey.
            </p>
            <div className="flex gap-4">
              <a href="/#/service-form">
                <button className="bg-pink-700 border-pink-700 text-white hover:bg-transparent hover:text-white hover:border-white py-2 px-6 lg:py-3 lg:px-8 text-lg lg:text-xl rounded-xl border-2">
                  Join us on this journey
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalHero;
