import React from "react";
import { ArrowCircleDownIcon, ArrowDownIcon } from "@heroicons/react/outline";
import BreadCrumbs from "../reuseable/BreadCrumbs";

const BlogHero = () => {
  return (
    <>
      <BreadCrumbs />
      <div className="h-fit HeroBg2 py-20 px-10 lg:px-28 lg:py-24">
        <h1 className="text-5xl md:text-6xl lg:text-7xl font-black py- lg:py-10 md:p-0 text-blue-700 text-center">
          Every story matters, and ELiNA has one to share too
        </h1>
        <div className="text-base md:text-xl font-semibold text-justify py-10 lg:py-12 lg:p-24">
          <p>
            At Elina, we believe that everyone has a story to tell – and so do
            we. Through our blog, we aim to inspire and guide parents,
            therapists, and educators by sharing our journey, along with the
            unique stories of the children and families we support. From
            overcoming challenges to celebrating small victories, our stories
            reflect the diverse experiences of those navigating the world of
            special needs care. Join us as we share valuable insights, practical
            tips, and inspiring narratives that empower you to make a positive
            impact. Elina’s story is here to connect with yours, offering
            support and understanding every step of the way.
          </p>
        </div>
        <div
          onClick={() =>
            document
              .getElementById("blogScroll")
              .scrollIntoView({ behavior: "smooth" })
          }
          className="text-center items-center flex justify-center py-10 lg:py-0 cursor-pointer"
        >
          <h1 className="font-black text-lg text-blue-700">Explore more</h1>
          <div className="items-center ">
            <ArrowDownIcon className="w-5 text-blue-700" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogHero;
